#locationPicker {
    position: relative;
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    font-family: RecoletaMedium, serif;
    font-size: var(--h2);
    color: var(--color-blue-dark);
    letter-spacing: -0.01em;
    line-height: 1.2222em; /* This is equivalent to 22pt */ 
  }
  
  #locationPicker input {
    height:60px;
    width: 100%;
    border: none;
    box-sizing: border-box;
    padding-left: var(--spacer1x);
    padding-right: var(--spacer1x);
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: 3px solid var(--color-white);

    font-family: RecoletaMedium, serif;
    font-size: var(--h2);
    color: var(--color-black);
    letter-spacing: -0.01em;
    line-height: 1em; 

    background-image: url('../svg/searchIcon.svg');
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: right 30px center; 
    display:none;
  }

  #locationPicker input:focus {
    outline: none; /* Remove the default outline */
  }
  #locationPicker input::placeholder {
    color: var(--color-black);
}
  
  #locationSuggestions {
    width: 100%;
    background-color: white;
  }
  
  #locationSuggestions .btn-locationSuggestion {
    padding-left: var(--spacer1x);
    padding-right: var(--spacer1x);
    padding-top: 7.5px;
    padding-bottom: 7.5px;
    cursor: pointer;
    user-select: none;
    border: none;
    color: rgba(77, 123, 150, 0.75);
  }
  #locationSuggestions .inputText {
    color: var(--color-black);
  }
  #locationSuggestions .btn-locationSuggestion.firstSuggestion{
    padding-top: 15px;
  }
  #locationSuggestions .btn-locationSuggestion.lastSuggestion{
    padding-bottom: 30px;
  }
  
  #locationSuggestions div:hover, #locationSuggestions .btn-locationSuggestion.selected {
    background-color: rgba(162, 190, 176, 0.05);
    color: var(--color-black);
  }