#locationContainer {
    /* background-color: aqua; */
    position: sticky;
    top:0;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    box-sizing: border-box; 
    width: 100%;
    padding: var(--spacer2x) var(--spacer2x); 
    z-index: 1;
    pointer-events: none;
}

#location {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 15px;
    overflow: hidden;
    width:var(--locationDivWidth);
}

/* ///////////////////////////////////////////////////////////////////////////////////////////////// SITE TITLE */

#location #siteTitle {
    padding-left: var(--spacer2x);
    padding-right: var(--spacer2x);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    height:40vh;
    /* height:240px; */
}
#location #siteTitle h2 {
    color: var(--color-black);
}   
#location #siteTitle #wave {
    /* width:65px; */
    /* position: absolute; */
    top:20%;
    width:50%;
    height: 9px;
    display: block;
    background-image: url('../svg/Wave.svg');
    background-repeat: no-repeat;
    /* margin: -15px 0 15px 0; */
    margin-top: -15px;
    margin-bottom: 15px;

}



/* ///////////////////////////////////////////////////////////////////////////////////////////////// LOCATION LABEL */

#location #locationLabel {
    flex-grow: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 30px;
}

#locationLabel #locationLabelText {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
}
#locationLabel #locationLabelText h1 {
    font-family: RecoletaMedium, serif;
}

/* ///////////////////////////////////////////////////////////////////////////////////////////////// BUTTONS */

#location #btn-start, #location #locationPicker, #location #btn-close {
    pointer-events: auto; 
}
#location #btn-start{
    display: flex;
    align-items: center;
    width: 100%; 
    height: var(--spacer2x);
    position: absolute;
    bottom: 0;
    /* margin-top: auto; */
    background-repeat: no-repeat;
    background-position: calc(100% - 30px) 50%; 
    cursor: pointer;
    /* background-color: aqua; */
    background-image: url('../svg/ArrowBtn.svg');
    /* display:none; */
}

#location #btn-close {
    background-image: url('../svg/closeBtn.svg');
    display: flex;
    align-items: center;
    width: var(--spacer3x);
    height: 100%;
    margin-top: auto;
    background-repeat: no-repeat;
    background-position: calc(100% - 50px) 50%; 
    cursor: pointer;
}
#location #btn-close:hover {
    background-image: url('../svg/closeBtnHover.svg');
}
#location #btn-start:hover {
    background-image: url('../svg/ArrowBtnHover.svg');
}

#locationLabel {
    display: none;
}

/* ///////////////////////////////////////////////////////////////////////////////////////////////// LOCATION RECOMENDATIONS */
#locationRecommendations{
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 15px;
    position: relative;
    width:var(--locationDivWidth);
    margin-top: var(--spacer1x);
    overflow: hidden;
    display: none;
}
.btn-locationRecommendation{
    height:60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: none;
    box-sizing: border-box;
    padding-left: var(--spacer1x);
    padding-right: var(--spacer1x);
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: 3px solid var(--color-white);

    font-family: RecoletaMedium, serif;
    font-size: var(--h2);
    color: #D9D7D3;
    letter-spacing: -0.01em;
    line-height: 1em; 

    background-image: url('../svg/WaveGreen.svg');
    background-size: 55px 9px;
    background-repeat: no-repeat;
    background-position: right 30px center; 

    cursor: pointer;
    pointer-events: auto;
}
.btn-locationRecommendation.last{
    border-bottom: 0;
}
.btn-locationRecommendation:hover{
    background-color: rgba(162, 190, 176, 0.05);
    color: var(--color-black);
    background-image: url('../svg/WaveGreenHover.svg');
}


/* ///////////////////////////////////////////////////////////////////////////////////////////////// MOBILE VARIANTS */

/* Small devices (mobile) */
@media (max-width: 767px) {

    #locationContainer{
        padding: var(--spacer1x) var(--spacer1x); 
    }
    /* #location, #locationRecommendations{
        width:100%;
    } */
    #location #siteTitle {
        height:35vh;
    }

    #location #btn-close {
        background-position: calc(100% - 30px) 50%; 
    }
}