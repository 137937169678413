
.graphContent {
    opacity: 0;
}

/* ///////////////////////////////////////////////////////////////////////////////////////////////// SCROLL CONTAINER 
                                                                                                     & WAVE CANVAS   */

#scrollContainer {
    position: relative;
    width: 100%;
    height: 100%;
    /* overflow-x: auto; */
    /* overflow-y: hidden; */
    overflow: auto;
    display: flex;
    flex-grow: 1;
    flex-direction: column; 
    align-items: flex-start; 
    overscroll-behavior: none;
    z-index: 2;
}

#scrollContainer #wave-canvas{
    position:absolute;
    display: inline-block;
    bottom:0;
    overscroll-behavior: none; 
}

/* ///////////////////////////////////////////////////////////////////////////////////////////////// INFO */

#daysContainer {
    margin-top: var(--spacer1x); 
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.day {
    /* margin-top: var(--spacer1x); */
    position:absolute;
    font-family: RecoletaSemiBold, serif;
    color: var(--color-blue-light);
    white-space: nowrap; /* Prevent text from wrapping */
    display: inline-block; /* Make width fit content */
}
#daysContainer .day.current {
    color: var(--color-black);
}


#info {
    /* margin-top: var(--spacer1x); */
    position: sticky;
    left: var(--infoLeft);
    z-index: 3;
}

#arrow{
    margin-top: 10px;
    margin-left: 15px;
    height: 0;
    border-left: 2px solid var(--color-red);
    border-radius: 1px;
    z-index: 4;

}

#arrow::after {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 8px;
        width: 16px;
        height: 9px;
        border-radius: 50%;
        background-image: url('../svg/arrowhead.svg');
        background-repeat: no-repeat;
        background-size: contain;
}

/* ///////////////////////////////////////////////////////////////////////////////////////////////// TICKS */

#scrollContainer #tickMarkContainer{
    position: relative;
    display: block;
    /* margin-top: var(--spacer2x); */
    z-index: 5;
}

#scrollContainer .tickMark{
    position:absolute;
    display: block;
    /* top: var(--tickMarkY);  */
    width: 2px;
    height: 5px;
    border-radius: 1px;
    background-color: var(--color-green);
    opacity: 0.5;
}
#scrollContainer .hourHighlightTick {
    background-color: var(--color-blue-dark);
    opacity: 1;
}

/* ///////////////////////////////////////////////////////////////////////////////////////////////// LABELS */

#scrollContainer h3{
    position: absolute;
    white-space: nowrap;
    text-align: center;
    color: var(--color-white);
    opacity: 0.9;
}
/* #scrollContainer h3.high{
    color: var(--color-blue-light);
}
#scrollContainer h3.low{
    color: var(--color-white);
    opacity: 0.9;
} */
#scrollContainer h3 span{
    display: block;
    line-height: 16px;
}
#scrollContainer h3 span.heightLabel{
    font-family: RecoletaMedium, serif;
    opacity: 0.75;
}

/* 
//////////////////////////////////  RESPONSIVE VARIABLES 
*/

/* Small devices (mobile) */
@media (max-width: 767px) {

    #info, #arrow {
        left: var(--infoLeft);
    }
    #scrollContainer #tickMarkContainer{
        /* margin-top: var(--spacer1x); */
  }
}
  /* Medium devices (tablets) */
  @media (min-width: 768px) {
  
  }
  
  /* Large devices (desktops, laptops) */
  @media (min-width: 992px) {
  
  
  }
