@import url('css/reset.css');
@import url('css/fonts.css');

/* 
//////////////////////////////////  GLOBAL VARIABLES 
*/

:root {
  --color-white: rgb(248, 245, 240);
  --color-black: rgb(53, 44, 48);
  --color-blue-light: rgb(95, 141, 167);
  --color-blue-dark: rgb(77, 123, 150);
  --color-green: rgb(162, 190, 176);
  --color-red: rgb(228, 93, 97);

  --h1: 40px;
  --h2: 18px;
  --h3: 12px;
  --btn: 16px;
  --p: 12px;

  --spacer1x: 30px;
  --spacer2x: 60px;
  --spacer3x: 90px;
  --spacer4x: 120px;
  --spacer5x: 150px;
  --spacer6x: 180px;
  --spacer7x: 210px;
  --spacer8x: 240px;
  --spacer9x: 270px;
  --spacer10x: 300px;

  --daysPerFetch: 10;               /* Number of days to fetch per API call */

  --locationDivWidth: 400px;         /* Width of the location div  */
  --locationLabelHeight: 120;       /* pixel height of #location div when showing chart label */

  --wavePanelWidth: 3;              /* Number of screen widths per wave panel */
  --canvasPercentage: 0.5;          /* Percentage of screen height to use for tide chart wave canvas */
  --splashCanvasPercentage: 0.45;   /* Percentage of screen height to use for spash screen wave canvas */
  --bottomBufferPercent: 0.5;       /* Space between lowest peak of wave and bottom of canvas */

  --labelOffsetHigh: -15px;         /* Adjusts the y position of HIGH tide labels on wave*/
  --labelOffsetLow: -15px;          /* Adjusts the y position of LOW tide labels on wave*/
  --tickMarkY: 25%;                 /* Y position of tick marks */
  --tickFrequencyInMinutes: 15;     /* How frequently ticks are added to the graph */

  --infoLeft: var(--spacer3x);      /* Left position of #info div */
  --arrowGap: 60;                   /* gap between arrow point and graph */
}

/* 
//////////////////////////////////  RESPONSIVE VARIABLES 
*/

/* Small devices (mobile) */
@media (max-width: 767px) {
  :root {
    --h1: 22px;
    --h2: 18px;

    --wavePanelWidth: 4;              /* Number of screen widths per wave panel */
    --bottomBufferPercent: 0.6;       /* Space between lowest peak of wave and bottom of canvas */
    --locationDivWidth: 100%;         /* Width of the location div  */
    --locationLabelHeight: 90;        /* pixel height of #location div when showing chart label */
    --tickFrequencyInMinutes: 30;     /* How frequently ticks are added to the graph */

    --infoLeft: var(--spacer2x);      /* Left position of #info div */
  }
}

/* Medium devices (tablets) */
@media (min-width: 768px) {

}

/* Large devices (desktops, laptops) */
@media (min-width: 992px) {


}

/* 
//////////////////////////////////  GLOBAL STYLES 
*/

h1 {
  font-family: RecoletaSemiBold, serif;
  font-size: var(--h1);
  color: var(--color-black);
  letter-spacing: -0.03em;
  line-height: 1em;
}

h2 {
  font-family: RecoletaMedium, serif;
  font-size: var(--h2);
  color: var(--color-blue-dark);
  letter-spacing: -0.01em;
  line-height: 1.2222em; /* This is equivalent to 22pt */
}

h3 {
  font-family: RecoletaBlack, serif;
  font-size: var(--h3);
}

p {
  font-size: var(--p);
 }

 button {
  font-family: RecoletaMedium, serif;
  font-size: var(--btn);
  color: var(--color-white);
  letter-spacing: 0em;
 }

.color-white {
  color: var(--color-white);
}
.color-black {
  color: var(--color-black);
}
.color-blue-light {
  color: var(--color-blue-light);
}
.color-blue-dark {
  color: var(--color-blue-dark);
}
.color-green {
  color: var(--color-green);
}

/* 
//////////////////////////////////  POSITIONING 
*/

html {
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
}

body {
  background-color: var(--color-white);
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  touch-action: pan-y; /* Prevents dragging issues on touch devices */
}

.App {
  display: flex;
  flex-direction: column;
  height: calc(100 * var(--vh));   
}

* {
  -webkit-tap-highlight-color: transparent;
}

